.background-gray {
  //  background-color: #051a4178;
    background-image: url('../../Assets/images/register_cover.jpg');
    //background-repeat: no-repeat;
     background-size: cover;
    padding-left: 30px;
    padding-right: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100%;
    width: 100% !important;
    margin-bottom: 90px;

    @media (max-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;

    }
}

.image {
    max-width: 200px;
    max-height: 200px;
    display: block;
    padding-top: 30px;
}

.heading-Contact {
    font-family: 'Oxygen', sans-serif;
    margin-top: 100px;
    font-weight: 700;
    color: white;
    font-style: normal;
    font-size: 40px;
    margin-left: 30px;
    text-align: left;
    padding-top: 120px;
}

.h2-contact {
    font-size: 30px;
    font-family: 'Oxygen', sans-serif;
    font-weight: 700;
    margin-left: 35px;
    color: #162447;
    align-items: left !important;
    display: flex;
    align-content: flex-start;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
}

.paragraph-contact {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 50px;
    font-family: open sans, sans-serif;
    padding-left: 40px;
    padding-right: 40px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: gray;
    text-align: left;
}

.container-contact {
    display: flex;
    align-content: flex-start;
    text-align: left;
    flex-direction: column;
    justify-content: center;
}

.label-contact {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-top: 0;
    font-family: open sans, sans-serif;
    padding-left: 0px;

    display: block;
    padding-top: 10px;
    color: gray;
    text-align: left !important;
    margin-bottom: 10px;
    margin-top: 5px;
}

.contact-input {
    width: 95%;
    box-sizing: border-box;
    height: 40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 8px;
    display: block;
}

.error-message {
    color: red;
    font-size: 10px;
    margin-top: 4px;
    margin-bottom: 0px;
    text-align: start;
}

.btn-container {
    display: flex;
    align-items: flex-start;
}

.button-contact {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    align-items: left !important;
    margin-bottom: 20px;
}

textarea {
    height: 95px;
    width: 95%;
    display: block;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    width: 95%;
    box-sizing: border-box;

    padding: 10px;

    border-radius: 4px;
    margin-bottom: 8px;
}

.form {
    margin-left: 10px;

    @media (max-width: 768px) {

        margin-left: 26px;
    }
}

.h2-contact1 {
    font-size: 30px;
    font-family: 'Oxygen', sans-serif;
    font-weight: 700;
    margin-left: 10px;
    color: #162447;
    text-align: left !important;

    @media (max-width: 768px) {
        padding-left: 20px;
        padding-right: 10px;

    }
}

.alignment {
    padding-left: 50px;
    padding-right: 50px;

    @media (max-width: 768px) {
        padding-left: 0px;
        padding-right: 10px;

    }
}