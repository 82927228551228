.card-login {
    max-width: 600px !important;
    height: 700px !important;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    box-sizing: border-box;
    margin-top: 0.5cm !important;
    margin: auto;
    text-align: center;
    margin-bottom: 30px;
   

}

.main {
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 27px;

}

.paragraph {
    padding-top: 0;
}

.button-login {
    margin-top: 15px;
    @media(max-width:600px){
        height: 40px;
        width: 100px;
        font-size: 13px;
    }
   
height: 50px;
width: 150px;
    font-weight: 900 !important;
    color: white;
    background-color: #f4053d;
    border: none;
    border-radius: 25px;
    cursor: pointer;
     font-size: 15px;
    
    align-items: center !important;
}

.color-blue {
    color: blue;
    font-weight: 400;
}

.end {
    text-align: end;
    //font-weight: 900 !important;
}

.padding-right {
    padding-right: 70px;
}

.padding-left {
    padding-left: 70px;
}

.start {
    text-align: start;

}



.label-login {
    top: 0;
    font-size: 12;
    background-color: white;
}

.input-container {
    position: relative;
    margin-bottom: 20px;
}

.input-login {
    height: 50px;
    width: 80%;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    padding: 10px 10px 10px 10px !important;
    box-sizing: border-box;
    transition: border-color 0.3s ease-in-out;
    border-radius: 25px;
    font-family: 'Oxygen', sans-serif !important;
}

.input-login:focus+label-login,
.input-login:not(:placeholder-shown)+label-login {
    top: 5px;
    left: 30px;
    font-size: 12px;
    color: black;
    background-color: #fff;
    padding: 0 40px;
}

.label-login {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: #888;
    pointer-events: none;
    transition: 0.3s ease-in-out;
}


.input-container {
    position: relative;
    margin-bottom: 25px;
}

.input-login {
    height: 50px;
    width: 80%;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    padding: 10px;
    box-sizing: border-box;
    transition: border-color 0.3s ease-in-out;
    border-radius: 25px;
}

.eye-icon {

    cursor: pointer;
    position: absolute;
    right: 79px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
}

.label-login {
    position: absolute;
    top: -1px;
    left: 30px;
    font-size: 12px;
    color: black;
    pointer-events: none;
    transition: 0.3s ease-in-out;
    padding-left: 50px;
    background-color: white;

}

.input-login:focus+label-login,
.input-login:not(:placeholder-shown)+.label-login {
    top: -15px;
    left: 30px;
    font-size: 12px;
    color: black;
    background-color: #fff;
    padding: 0 10px;
}

.paragraph1 {


    color: #888;

}

.image-login {
    padding-top: 65px;
    align-items: center;
        max-width: 200px;
        max-height: 200px
}

.padding {
    padding-top: 40px !important;

}

.error-message {
    color: red;
    font-size: 10px;
    margin-top: 4px;
    margin-bottom: 0px;
    text-align: start;
}