// styles.scss
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Bricolage+Grotesque&family=Oswald:wght@200;300;600&family=Oxygen:wght@300;400;700&family=Poppins:ital,wght@0,100;1,100&family=Roboto+Slab:wght@200&family=Tilt+Prism&display=swap');

// .h4 {
//   font-family: 'Oxygen', sans-serif;
//     color: white;
//     font-size: 1.5rem;
//     background-color: #28467A !important;
//     padding: 10px;
//     width:300px;
//     height: 30px;
//     border-radius: 15px;
//     text-align: left !important;



//     @media screen and (max-width: 600px) {
//       font-size: 1.5rem !important;
//       width: 100%;
//     }
//   }
.h9 {
  font-family: 'Oxygen', sans-serif;
  color: white;
  font-size: 1.4rem;
  text-align: left;
  background-color: #28467A !important;
  padding: 8px;
  width: 400px;
  //width: 100%;
  height: 30px;
  border-radius: 10px;
  text-align: left !important;

  @media screen and (max-width: 600px) {
    font-size: 1.2rem !important;
    width: 250px;
    height: 43px;
  }
}

.h5 {
  font-family: "Oxygen";
  color: #0e0c0c;
  text-align: left;
}

.h2 {
  font-family: "Oxygen";
  margin-left: 30px;
  text-align: left;
}

.contact {
  margin-left: 60px;
  /* Default margin-left */
}

.contact1 {
  padding: 10px;
  background: rgb(22, 91, 141);
  border: 1px solid #D1CECA;
  width: 100%;

}

/* Registrationstyles.scss */

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;

  .iconWrapper {
    position: absolute;
    background-color: #28467A;
    /* Blue background color */
    padding: 9px;
    border-radius: 4px;
    z-index: 1;
    /* Ensure the icon appears on top */


    svg {
      color: #FFFFFF;
      /* Icon color */
    }
  }

  input.inputWithIcon {
    width: 290px;
    height: 38px;
    padding-left: 46px;
  }
}

.inputContainer1 {
  position: relative;
  display: flex;
  align-items: center;


  .iconWrapper1 {
    position: absolute;
    background-color: #28467A;
    padding: 9px;
    border-radius: 4px;
    z-index: 1;
  }

  .iconWrapper1 svg {
    color: #FFFFFF;
  }

  input.customSelectdate {
    width: 100%;
    /* Adjust width for input */
    height: 40px;
    /* Set a specific height for input */
    padding-left: 10px;
    /* Adjust padding */
  }
}

/* Media query for mobile devices */
@media (max-width: 600px) {


  input.customSelectdate {
    width: 100% !important;
    /* Adjust width for smaller screens */
  }
}

.customSelectdate {
  width: 340px;
  height: 43px;
  padding-left: 36px;
  border: black;
  background-color: white;
}

.customSelectdate .MuiInputBase-input {
  height: 27px;
  /* Adjust the height as needed */
  padding: 8px 13px;
  /* Adjust the padding as needed */
  padding-left: 30px;
  width: 100%;
}

.inputWithIcon1 {
  width: 500px;
  height: 38px;
  padding-left: 46px;
}

.inputWithIcon2 {
  width: 100%;
  height: 43px;
  padding-left: 46px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

.inputWithIcon2Select {
  width: 100%;
  height: 43px;
  padding-left: 41px;
  border: black;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .inputWithIcon2Select2 {
    border: 1px solid rgb(147, 146, 146);
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    width: 90% !important;
    margin-left: 40px !important;
  }
}

.inputWithIcon2Select2 {
  //width: 100%;
  height: 43px;
  //padding-left: 41px;

  //border:1px solid rgb(48, 48, 48);
}

.css-qbdosj-Input {
  border-color: black !important;
  padding: 5px !important;
  padding-top: 3px !important;
  padding-bottom: 9px !important;
  color: black !important;
}

.paper {
  // padding: 10px;
  // margin: 5px;
  background-color: #f7f7f7;


}

.paper {
  //  position: relative; /* Add position relative to establish a positioning context */
  padding: 10px;
  // margin: 30px;
  background-color: #f7f7f7;
  border-radius: 10px;
  border: 1px solid #d1d1d1;
}

.h4 {
  position: absolute;
  /* Use absolute positioning for the heading */
  z-index: 1;
  // padding: 0 10px;
  margin: -20px 0 0px;
  font-size: 1.3rem;
  // height:35px;
  padding-left: 6px !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-right: 6px !important;
  text-align: center;
  font-family: 'Oxygen', sans-serif;
  color: white;
  background-color: #28467A !important;
  border-radius: 5px;

  @media screen and (max-width: 600px) {
    font-size: 1.3rem !important;

  }

}

.paper1 {

  padding: 10px;
  margin: 30px;
  // padding-left: 30px;

}

.paper2 {
  padding: 10px;
  margin: 30px;

}

.paper3 {
  padding: 10px;
  margin: 10px;
  // padding-left: 60px;

}
.paper-guidelines {
  padding: 2px;
  margin-top: 50px !important;
  justify-content: center !important;
  text-align: left;
}

/* ContactDetails.css */
.customSelect {
  text-align: left;
  margin-left: 40px !important;
  width: 300px;
  height: 45px;
  padding-left: 10px;
  //  margin-left: 2.9%;
  background-color: white;
}

.customSelect option {
  /* Styles for the options within the select */
  font-size: 16px;
  padding: 5px 10px !important;

}

.customSelect3 {
  background-color: white;
}

.customSelect1 {
  width: 480px;
  height: 45px;
  background-color: white;
}

.customSelectone {
  width: 320px;
  height: 40px !important;
  margin-left: 19px !important;
  padding: 2px 2px !important;
  background-color: white;
}

.customSelectone .MuiInputBase-input {
  height: 27px;
  /* Adjust the height as needed */
  padding: 8px 13px;
  /* Adjust the padding as needed */
  padding-left: 20px;
  background-color: white;
}

.customSelectdate {
  width: 100%;
  height: 43px;
  padding-left: 36px;
  border: black;
}

.customSelectdate .MuiInputBase-input {
  height: 27px;
  /* Adjust the height as needed */
  padding: 8px 13px;
  /* Adjust the padding as needed */
  padding-left: 50px;
  padding-right: 50px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background-color: white;
}

td,
th {
  border: 1px solid #666666;
  text-align: left;
  padding: 10px;
  width: 20px
}

.container {
  height: 300px;
  /* Adjust the height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-button {
  padding: 10px 20px;
  /* Adjust padding as needed */
  /* Additional styles for the button */
}

.big-vertical-button {
  display: flex;
  width: 250px;
  height: 55px;
  margin-bottom: 10px;
  color: white !important;
  background-color: red !important;
  border-radius: 10px !important;
  font-size: 16px !important;

  margin-left: 1px;
  font-weight: 900 !important;

}

.big-checkout-button {
  display: flex;
  width: 200px;
  height: 55px;
  margin-bottom: 10px;
  color: white !important;
  background-color: rgb(246, 83, 83) !important;
  border-radius: 10px !important;
  font-size: 13px !important;
  font-weight: 900 !important;
  padding: 10px !important;

}

.big-failure-button {
  display: flex;
  width: 200px;
  height: 55px;
  margin-bottom: 10px;
  color: rgb(246, 83, 83) !important;
  background-color: white !important;
  border-radius: 10px !important;
  font-size: 13px !important;
  border: 1px solid red !important;
  font-weight: 900 !important;
  padding: 10px !important;
  

}

.payment_button_container{
  flex-direction: row !important;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 600px) {
    padding: 10px; 
    flex-direction: column !important; 
    align-items: center !important;  

  }
  
}
.search-button {
  display: flex;
  width: 18px;
  height: 45px;
  margin-top: 20px !important;
  color: white !important;
  background-color: #28467A !important;
  border-radius: 10px !important;
  font-size: 20px !important;

  margin-left: 1px;
  font-weight: 900 !important;

}

.css-zow5z4-MuiGrid-root {
  margin-left: 0px !important;
  width: 100% !important;
  margin-top: 0px !important;

}

/* amount details scss*/
.containercheckout {

  .heading-retry{
    margin-top: 100px !important;
  }

  .big-vertical-button {
    display: flex;
    width: 250px;
    height: 55px;
    margin-bottom: 10px;
    color: white !important;
    background-color: red !important;
    border-radius: 10px !important;
    font-size: 15px;
    font-weight: 800;
    margin-left: 1px;

  }

  .header {
    padding: 10px;
    margin: 10px;

  }

  .h1 {
    font-size: 1.3rem;
    margin-left: 100px !important;
    margin-right: 100px !important;
    text-align: left;
    padding: 5px !important;

    @media screen and (max-width: 600px) {
      margin-left: 50px !important;
      margin-right: 50px !important;

    }
  }

  .bankdetailscontainer {
    margin-left: 100px !important;
    margin-right: 100px !important;
    text-align: left;
    background-color: #f7f7f7;
    padding: 20px;
    border: 1px solid #514e4e;
    border-radius: 10px;

    @media screen and (max-width: 600px) {
      margin-left: 50px !important;
      margin-right: 50px !important;

    }

    .textstyle {
      font-family: 'Oxygen', sans-serif;
      font-size: 1.2rem;

    }

  }

  .amountcontainer {
    margin-left: 100px !important;
    margin-right: 100px !important;

    @media screen and (max-width: 600px) {
      margin-left: 50px !important;
      margin-right: 50px !important;

    }

    .content {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }

    .amount-table {
      border-collapse: collapse;
      width: 100%;
      // background-color: #dddddd !important;
      border: #0e0c0c !important;

      th {
        border-bottom: 1px solid #dddddd !important;
        border-top: 1px solid #dddddd !important;
        border-right: none !important;
        border-left: none !important;
        font-weight: 700;
        background-color: #f7f7f7;

      }

      td {
        border: none;
        // padding: 8px;
        text-align: left;
        font-size: 1.2rem;
        background-color: #f7f7f7;

      }

      th:nth-child(2),
      td:nth-child(2) {
        text-align: right;
      }

      tr {
        &:not(:last-child) {
          border-bottom: 1px solid #dddddd;
        }
      }

      th {
        font-size: 1.2rem;
        color: rgb(23, 22, 22);

      }

      td {

        border-bottom: 1px solid #dddddd;

      }

      tr:last-child {
        background-color: #630f0f;
      }

      tr:last-child td {
        font-weight: bold;
        background-color: #cecccc;
      }

      @media (max-width: 768px) {
        th {
          font-size: 14px;
        }
      }
    }

    .retry-table {
      border-collapse: collapse;
      width: 100%;
      // background-color: #dddddd !important;
      border: #4f4e4e !important;

      th {
        border-bottom: 1px solid #dddddd !important;
        border-top: 1px solid #dddddd !important;
        border-right: none !important;
        border-left: none !important;
        font-weight: 700;
        background-color: #f7f7f7;
      }

      td {
        border: none;
        // padding: 8px;
        text-align: left;
        font-size: 1.0rem;
        background-color: #f7f7f7;

      }

      th:nth-child(2),
      td:nth-child(2) {
        text-align: right;
      }

      tr {
        &:not(:last-child) {
          border-bottom: 1px solid #dddddd;
        }
      }

      th {
        font-size: 1.0rem;
        color: rgb(23, 22, 22);

      }

      td {

        border-bottom: 1px solid #dddddd;

      }

      tr:last-child {
        background-color: #630f0f;
      }

      tr:last-child td {
        font-weight: bold;
        background-color: #e3e0e0;
      }

      @media (max-width: 768px) {
        th {
          font-size: 14px;
        }
      }
    }
  }
}

.categoryselection {
  scroll-margin: 120px;
}

.workshopselection {
  scroll-margin: 150px;
}

.selectDropdown {
  width: 100%;
  font-size: 18px;
  text-align: left;
}