.memberview-container{
  align-items: left !important;
}




.memberview-container {
align-items: center !important;
  border: 1px solid #ddd;
  align-items: left !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width:97% !important;
  box-sizing: border-box;
  margin: auto;
  height: max-content !important;
  padding: 10px; 
  background-color:#f7f7f7 !important;
  padding: 5px 24px;

border-radius: 10px;
  @media (min-width: 600px) {
    margin-right: 30px !important;
    margin-left: 30px !important;

  }

  @media (max-width: 600px) {
    align-items: center !important;
  }
}


.memberview-inputs {
  padding-top: 2px !important;
  padding-left: 0px!important;
  padding-bottom: 15px;
  // margin-top: 6px;

  @media (max-width: 600px) {

      padding-top: 20px !important;


  }
}

.memberview-input {
  padding-top: 10px !important;
  padding-left: 0px!important;
  padding-bottom: 15px;

  @media (max-width: 600px) {

      padding-top: 96px !important;


  }

}
.label-memberview{
  font-size: 17px !important;
  flex: auto;
  font-variant:inherit;
  align-items: left !important;
  display: flex;
  font-weight: 300;
  margin-top: 23px;
}

@media (max-width: 600px) {
  .responsive-input {
      padding-top: 10px !important;
  }


}



.input-memberview {
  width: 90%;
  height: 36px !important;
  padding-left: 5px;
  padding-right: 5px;
  

  @media(max-width:600px) {
      width: 95%;
      height: 56px;
    
      margin-top: 10px;

  }
}



.button-view{
  background-color:rgb(74, 127, 241) !important;
  color:#ffffff;
  border-radius: 5px !important;
  padding:4px 10px;
  border: none;
  cursor: pointer;
  height: 40px !important;
  width: 90px !important;
}
.input-select-edit{
  width:90% !important;
  background-color: #ffffff !important;
  height: 40px !important;
  float: left;
  width: 93%!important;
  .responsive-input {
      padding-top: 10px !important;
      width: 100%!important;
  }


}




.customSelectdate {
  width:94%;
  height: 43px;
  // padding-left: 36px;
  border: black;
  background-color: white;
  @media (max-width:600px) {
    width:99% !important;
    
  }
}

.customSelectdate .MuiInputBase-input {
  height: 27px;
  /* Adjust the height as needed */
  // padding: 8px 13px;
  // /* Adjust the padding as needed */
  // padding-left: 30px;
  width: 100% !important;
  @media (max-width:600px) {
    width: 99% !important;
  }
}
.memberview-inputs-select{
padding-top: 25px !important;
// margin-top: 6px;

@media (max-width: 600px) {

    padding-top: 20px !important;


} 
}
.edit1{
width: calc(100% + -1px) !important;
margin-left: 0 !important;

flex-direction: row !important;
}

.memberview-input{
padding-left: 0px!important;
}
.dte input{
padding-left: 8px!important;
}

.input-select-edit div{
text-align: left}

@media (max-width:600px) {
 .input-select-edit {
  width: 99% !important;  
}
}

.otherdt{
padding-bottom: 30px !important;
}
.error-msg{
margin-top: -19px !important;
position: absolute;
}
.city-edit{
width:92% !important;
height: 34px !important;
@media(max-width:600px){
  width:100% !important;
}
}
.state-edit{
width:92% !important;
height: 43px !important;
@media(max-width:600px){
  width:100% !important;
}
}
.error-msg1{
margin-top: 10px !important;
position: absolute;
}
.id-document-display-container {
text-align: left; 
padding: 10px; 
}

.uploaded-document-text {
display: block; 
font-size: 20px; 
font-weight: bold; 
margin-bottom: 8px; 
}

.id-document-display {
display: block; 
width: auto; 
height: auto;
}

