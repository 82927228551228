// Dashboard.scss
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
.dashboard-container {
    background-color: #e8eff6;
    min-height: 100vh;
    .sidebar {
        height: 100%;
      }
    .content-container {
      padding: 20px;
      text-align: center;
    }
    @media only screen and (max-width: 600px) {
      .content-container {
        padding: 0%;
        margin-left: -30px;
          text-align: center; /* Adjust the padding value as needed */
      }
  }
    
  }
  .greyLine {
    margin-left: 22px; /* Adjust the margin left as needed */
    margin-right: 22px; /* Adjust the margin right as needed */
    height: 1px; /* Height of the line */
    background-color: #ebe7e7; /* Grey color */
  }
  .progressText {
    position: absolute;
    top: 50%; /* Adjust the position from the top as needed */
    left: 50%; /* Adjust the position from the left as needed */
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .progressText1 {
    text-align: center;
    margin-top: 10px; /* Adjust the margin as needed to separate the text from the progress bar */
  }
    .logoutContainer{
      display: flex;
      align-items: center;
      margin-top: -20px;
      margin-right: 25px;
    }
    @media only screen and (max-width: 600px) {
      .logoutContainer {
          display: none;
      }
  }
    .logout-icon{
      color: black;
      margin-right: 10px;
    }
    .logout-text{
    font-size: 15px !important;
    font-family:sans-serif !important;
    font-weight: bold !important;
    color: black !important;
    }
    .header-with-logout-container{
      justify-content:space-between;
      align-items: center;
    }
    .rowview{
      display: flex;
      margin-left: 20px;
      margin-right: 25px;
      align-items: center;
    }
    .rowview1{
      display: flex;
      margin-left: 20px;
      margin-right: 25px;
      align-items: center;
    }
    @media only screen and (max-width: 600px) {
      .rowview1 {
          display: none;
      }
  }
    .dashboard-icon{
      font-size: 18px;
      color: #0F1A51;
    }
    .dashboard_title{
      font-size: 16px;
      color: #0F1A51;
      font-family:Poppins !important;
      margin-left: 10px !important;
    }
    .menuitemtext{
      font-size: 12px !important;
      font-family:  sans-serif !important;
      font-weight: 600;
    }