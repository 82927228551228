.category-container {

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;

  .content-row {
    display: flex;
    flex-direction: row;
    align-items: left;

  }

  .category-table {
    border-collapse: collapse;
    width: 100%;


    th,
    td {
      border: 1px solid #dddddd;
      // padding: 8px;
      text-align: left;

    }

    tr {
      &:not(:last-child) {
        border-bottom: 1px solid #dddddd;
      }
    }

    th {
      background-color: #28467A;
      color: white;

    }

    td {
      &:nth-child(even) {
        background-color: #f7f7f7
      }
    }

    @media (max-width: 768px) {
      th {
        font-size: 14px;
      }
    }
  }
}

.background-image::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #0000007d;
}

.categoryselectionmessage {
  text-align: left;
  color: red;
}

.imgSelectionmessage{
  padding-left: 10px;
  margin-left: 30px;
  text-align: left;
  color: red;
}

.grid-container {
  position: relative;
  height: 70vh;


  .background-image {
    position: relative;
    height: 100%;
    width: 100%;
    background-image: url('../../Assets/images/register_cover.jpg');
    background-size: cover;
    background-color: rgba(56, 48, 48, 0.5);


    img {
      margin-top: 80px;
      max-width: 20%;
      width: 100%;
      height: auto;
      // display: block;
    }

    .wrap_header {
      padding-left: 30%;

      @media screen and (max-width: 600px) {
        padding-left: 0%;
      }
    }

    .text-overlay {
      position: absolute;
      color: white;
      font-size: 24px;
      text-align: left;
      width: 100%;
      height: 100%;
      text-align: center;
      box-sizing: border-box;
      z-index: 1;


      &.left {
        top: 10px;
        left: 0;
        padding-left: 10px;
      }

      &.right {
        top: 10px;
        right: 0;
        padding: 50px;

        .right-container {
          width: calc(100%);
          height: 100%;
          padding: 10px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: left;

          .logo {
            width: 100%;
            max-width: 200px;
            height: auto;
            text-align: center;
            // display: block;
            margin-bottom: 10px;
          }




          .chaptertext {
            text-align: left;
            font-size: 23px;
            font-weight: 600;
            padding-top: 20px;
            padding-bottom: 20px;
          }


          .place-container {
            padding-top: 20px;
            display: flex;
            align-items: left;
            // width: 320px;
            height: 32px;



            .placetext {
              text-align: left;
              font-size: 14px;
              font-weight: 500;
              margin-left: 10px;
            }
          }


        }
      }
    }
  }





  @media (max-width: 768px) {

    // .grid-container {
    //   position: relative;
    //    height: 50vh;
    .background-image {
      .text-overlay {
        &.right {
          padding: 2px;

          .right-container {
            padding: 3px;

            .logo {
              max-width: 80%;
              margin-bottom: 2px;
            }

            .heading {
              text-align: left;
              font-size: 15px;
              font-weight: bolder;
              padding-top: 1px;
            }

            .societytext {
              text-align: left;
              font-size: 8px;
              font-weight: bolder;
              padding-top: 1px;
            }

            .chaptertext {
              text-align: left;
              font-size: 8px;
              font-weight: 600;
              padding-top: 2px;
              padding-bottom: 2px;
            }

            .date-container {
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
              width: 70px;
              height: 10px;
              background-color: red;
              border-radius: 5px;

              .custom-icon {
                font-size: 8px;
              }

              .datetext {
                text-align: left;
                font-size: 8px;
                font-weight: 600;
                margin-left: 1px;
              }
            }

            .place-container {
              padding-top: 1px;
              display: flex;
              align-items: left;
              //width: 70px;
              height: 10px;

              .custom-icon {
                font-size: 8px;
              }

              .placetext {
                text-align: left;
                font-size: 6px;
                font-weight: 600;
                margin-left: 1px;
              }
            }

          }
        }
      }
    }

  }
}

// }

.heading {
  // text-align: left !important;
  font-size: 58px !important;
  font-weight: 700 !important;

  @media screen and (max-width: 600px) {
    font-size: 30px !important;
  }
}

.societytext {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  padding-top: 10px;

  @media screen and (max-width: 600px) {
    font-size: 20px !important;
  }

}

.date-container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 230px;
  height: 50px;
  background-color: red;
  border-radius: 10px;


  .custom-icon {
    font-size: 28px;
  }

  .datetext {
    text-align: left;
    font-size: 24px;
    font-weight: 600;
    margin-left: 10px;
  }

  @media screen and (max-width: 600px) {
    width: 130px;
    height: 50px;

    .datetext {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      margin-left: 5px;
    }

    .custom-icon {
      font-size: 14px;
    }
  }

}

.place-container {
  padding-top: 20px;
  display: flex;
  // align-items: left;
  // width: 320px;
  height: 32px;

  align-items: center;
  text-align: center;
  justify-content: center;


  .placetext {
    // text-align: left;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
  }
}

.fileupload {
  margin-top: 30px;

  .filesubmit {
    display: flex !important;
    justify-content: center;
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center !important;
    color: white !important;
    background-color: #28467A !important;
    font-size: 16px !important;
    border-radius: 8px;
    margin-left: 1px;
    font-weight: 900 !important;
  }
}

.id-document-display {
  padding-top: 5px;
  width: auto;
  height: auto;
}

.document-info-text {
  color: red;
}
