
//@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400&display=swap');
/*------------------------------------header section--------------------------------------------------------------------------------------------*/
.header {
background-color: white !important;
  margin-top: 1rem !important;

  .font-family {
    font-family: "Oxygen", sans-serif !important;
    text-align: start !important;
    font-size: 1.625rem !important;
    color: #000;
    font-weight: 700;
    @media screen and (max-width: 600px) {
      font-size: 0.8rem !important;
    }
    @media screen and (min-width: 600px) and (max-width: 900px) {
      font-size: 1rem !important;
    }
  }
  .blue_font {
    color: #28467a;
    font-family: "Oxygen", sans-serif !important;
  }
  .header_logo {
    width: 45% !important;
    // height: 60% !important;

    @media screen and (max-width: 600px) {
      width: 80px !important;
    }
  }
}
/*------------------------------------HomeContainer1 section--------------------------------------------------------------------------------------------*/
.home_container {
  width: 100%;
  position: relative;
  height: 750px;
  @media screen and (max-width: 600px) {
    height: 750px;
  }
  background-image: url("../../../Assets/images/KATCON_banner.jpg");
  background-size: cover;
  background-position: center;

  .home_content {
    top: 4rem;
    position: relative;
    z-index: 1;
    color: white;
  }
  .home_heading1 {
    font-family: "Oxygen", sans-serif !important;
    font-size: 3rem !important;
    @media screen and (max-width: 600px) {
      font-size: 2.2rem !important;
    }
    font-weight: 700 !important;
  }

  .home_heading2 {
    font-family: "Oxygen", sans-serif !important;
    font-size: 2rem !important;
    @media screen and (max-width: 600px) {
      font-size: 1.2rem !important;
    }
    font-weight: 500 !important;
  }
  .home_heading3 {
    font-family: "Oxygen", sans-serif !important;
    font-size: 1.5rem !important;
    font-weight: 500 !important;
  }
  .program {
    border: none;
    outline: none;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    background-color: #0a136a;
    border-radius: 2px;
    /* display: inline-block; */
    vertical-align: middle;
    text-align: center;
    font-size: 22px;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
    padding: 10px 20px;
    font-weight: bold;
    margin: 0 6px 5px;
    text-decoration: none;
    font-family: "Oxygen", sans-serif !important;
    cursor: context-menu;
  }

  .program_details {
    margin-top: 2.5rem;
  }

  .count {
    margin-top: 6.5rem;
  }

  .count .days {
    display: inline-block;
    margin: 0 12px 12px;
    font-size: 28px;
    @media screen and (max-width: 600px) {
      padding: 9px;
      width: 50px;
      height: 54px;
      font-size: 18px;
      margin: 0 4px 12px;
    }
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 11px;
    width: 65px;
    height: 65px;
  }

  .count .dy {
    font-size: 17px;
    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  .home_reg_btn {
    align-items: center;

    top:2.5rem !important;

    background-color: red;
    margin-bottom: 50px;
  
  }
  .home_reg_text {
    color: #fff !important;
    font-size: 1.25rem !important;
    padding: 5px 50px;
    font-weight: 600;
    font-family: "Oxygen", sans-serif !important;
  }
  .home_reg_btn:hover {
    background-color: #cb1e1e;
  }


  .scrolling-text {
    width: 100%;
    @media screen and (max-width: 600px) {
      font-size: 1.2rem;
    //  animation: scrollText 20s linear infinite !important;
    }
  }
  
  @keyframes scrollText {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .scrolltext {
    display: inline-block !important;
    color: black;
    background-color: yellow;
    border-radius: 2px;
    text-align: center;
    font-size: 17px;
  
    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
  
    padding: 5px 10px;
    font-weight: bold;
    margin: 0 8px 1px;
    text-decoration: none;
    font-family: "Oxygen", sans-serif !important;
  }
}  

.home_container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #0000007d;
}

button.pulse-button {
  animation: borderPulse 1000ms infinite ease-out,
    colorShift 10000ms infinite ease-in;
}

/* Initiate color change for pulse-on-hover */
button.pulse-button-hover {
  animation: colorShift 10000ms infinite ease-in;
}

/* Continue animation and add shine on hover */
button:hover,
button:focus {
  animation: borderPulse 1000ms infinite ease-out,
    colorShift 10000ms infinite ease-in, hoverShine 200ms;
}

/* Declare border pulse animation */
@keyframes borderPulse {
  0% {
    box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255, 0.4),
      0px 0px 0px 0px rgba(255, 255, 255, 1);
  }
  100% {
    box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 255, 0.2),
      0px 0px 0px 10px rgba(255, 255, 255, 0);
  }
}

/*------------------------------------------------------------------HomeContainer2 section----------------------------------------------------*/
@media (min-width: 1200px) {
  .home_container2 {
    max-width: 1170px;
    margin: 0 auto;
  }
}

@media (max-width: 1200px) {
  .home_container2 {
    padding-left: 25px;
    padding-right: 25px;
  }
}

// @media screen(min-width: 1200px;){
//     .home_container2 {
//         text-align: start;
//         margin: 2%;

//   }

.home_container2 {
  text-align: start;
  //   margin: 2%;

  // margin-top: 40px;

  // background-color: #28467A;
  //   height: 200px;

  .red_border{
    border: 3px solid green !important;
    border-radius: 5px !important;
  }
  .font_style {
    align-items: center !important;
    padding-bottom: 5px;
    font-family: "Oxygen", sans-serif !important;
    // font-weight: 700 !important;
    font-size: 1.1rem !important;
    @media screen and (max-width: 600px) {
      font-size: 0.8rem !important;
    }
  }
  .members_name {
    padding-bottom: 5px;
    font-family: "Oxygen", sans-serif !important;
    font-weight: 700 !important;
    font-size: 1.0rem !important;
    @media screen and (max-width: 600px) {
      font-size: 0.8rem !important;
    }
  }


  .font_style1 {
    font-family: "Oxygen", sans-serif !important ;
    font-size: 1rem !important;
    margin-bottom: 15px;
    @media screen and (max-width: 600px) {
      font-size: 0.8rem !important;
      padding-left: 5%;
    }
  }
  .align_fontStart{
    text-align: start !important;
    display: flex !important;
   
  
  }

  .align_fontSt_org{
    text-align: start !important;
    padding-left: 5%;
    @media screen and (max-width: 600px) {
      // font-size: 0.8rem !important;
      padding-left: 1%;
      // text-align: center !important;
    }
  }
  

  .font_bold{
    font-family: "Oxygen", sans-serif !important ;
    font-size: 1rem !important;
    font-weight: 700 !important;
    @media screen and (max-width: 600px) {
      font-size: 0.8rem !important;
   
    }
  }
  .font_normal{
    font-family: "Oxygen", sans-serif !important ;
    font-size: 1rem !important;
    font-weight: 400 !important;
    @media screen and (max-width: 600px) {
      font-size: 0.8rem !important;
   
    }
  }

  //home table color

  .category-table {
    border-collapse: collapse;
    width: 100%;
   
  
    th, td {
      border: 1px solid #dddddd;
     // padding: 8px;
      text-align: center;
      font-family: '"Oxygen", sans-serif !important';
     
    }
  
    tr {
        &:not(:last-child) {
          border-bottom: 1px solid #dddddd;
          font-family: "Oxygen", sans-serif !important;
        }
      }
      th {
        background-color: #28467A; 
        color: white;
        font-family: "Oxygen", sans-serif !important;
        font-weight: 700 !important;
      
      }
     
  td {
    &:nth-child(even) {
      background-color:#f7f7f7;
      font-family: "Oxygen", sans-serif !important;
    }
  }
  @media (max-width: 768px) {
    th {
      font-size: 14px; 
      font-family: "Oxygen", sans-serif !important;
    }
  }
}


  .center_align {
    padding: 25px;
    text-align: center !important;
    // position: relative;
    // top: 50px;
    margin-top: 50px;
    background-color: rgb(198 198 255 / 17%)
  }

  
  .center_align2 {
    padding: 25px;
    text-align: start !important;
    // position: relative;
    // top: 50px;
    margin-top: 50px;
    background-color: rgb(198 198 255 / 17%)
  }
  .font_style_header {
    font-family: "Oxygen", sans-serif !important ;
    font-size: 1.7rem;
    font-weight: 700 !important;
    @media screen and (max-width: 600px) {
      font-size: 1rem !important;
    }
  }
  .font_style_subheader {
    font-family: "Oxygen", sans-serif !important ;
    font-size: 1.5rem;
    font-weight: 700 !important;
    @media screen and (max-width: 600px) {
      font-size: 1rem !important;
    }
  }
  .home_container_para {
    text-align: justify !important;
    align-items: center !important;
  }

  .home_workshop_ct {
    display: "flex";
    padding-top: "10px";

    margin-top: 20px;
    // margin-left: 20px;

  }
  .workshop_last {
    margin-top: 10px;
    text-align: start;
  }
}

.linkcolor{
  color:blue !important
}
.linkwhite{
  color:white !important
}
.linkcolor2{
  color:blue !important;
  font-weight: 800;
  
}
.linkcolor3{
  color:black !important;
  font-weight: 800;
   text-decoration: none;
}
/*------------------------------------Home Contact us section--------------------------------------------------------------------------------------------*/
.home_contact {
  // background-color: antiquewhite !important;
  height: auto;
  margin-top: 50px;
  color: #000;

  .contact_header {
    font-family: "Oxygen", sans-serif !important;
    font-size: 1.4rem !important;

    font-weight: 700 !important;
    @media screen and (max-width: 600px) {
      font-size: 1.0rem !important;
    }

  }

  .contact_content{
    font-family: "Oxygen", sans-serif !important;
    font-size: 1.0rem !important;

    font-weight: 400 !important;
    @media screen and (max-width: 600px) {
      font-size: 0.8rem !important;
    }
  }
  .align_row{
    display: flex;
    padding: 5px;
    align-items: flex-start;
  }
  .contact_button{
    margin:10px auto;
  background-color: #0a136a !important;

  }
  .icon_size{
    font-size: 20px !important;
    padding-right: 2px;
  }

}

/*-----------------------------------------------------Footer--------------------------------------------------------------------------------------------*/
.footer {
  // margin-top: 10px;
  background-color: #0a136a;
  height: auto;
  padding-top: 40px;
  padding-bottom: 40px;

  .footer .a{
    color: #fff;
  }
  .footerlink{
    color: #fff;
    text-decoration: none !important;
  }
  .footer_content {
    align-items: center !important;
    align-content: center !important;
    // padding-top: 2rem;
  }
  .footer_header {
    font-size: 1.2rem;
    color: white;

    font-weight: 700;
    font-family: "Oxygen", sans-serif !important;
    @media screen and (max-width: 600px) {
      font-size: 0.8rem !important;
    }
  }

  .footer_content {
    padding: 0.7%;
    font-size: 1rem;
    color: white;
    font-weight: 500;
    font-family: "Oxygen", sans-serif !important;
    @media screen and (max-width: 600px) {
      font-size: 0.5rem !important;
    }

  }

}

/*--------------------------------------------------------------------------------venue distance-------------------------------------------------------------------------------*/

.venue_distance{
    margin-top: 50px;
    background-color: #4d65af;
    color: white;
    padding: 40px;
    height: auto;
    
    .venue_items{
        padding-left: 6%;
        padding-right: 6%;
        padding-top: 2%;
        padding-bottom: 1%;
    }
    .header_font{
        font-size: 1.2rem !important;
        font-weight: 700 !important;
        font-family: "Oxygen", sans-serif !important;
        @media screen and (max-width: 600px) {
          font-size: 0.8rem !important;
        }
    }

    .small_font{
        font-size: 1.0rem !important;
        font-weight: 400 !important;
        font-family: "Oxygen", sans-serif !important;
        @media screen and (max-width: 600px) {
          font-size: 0.6rem !important;
        }
    }
  }
  /*------------------------------------------------------gallery image slider-------------------------------------------------------------------------*/
 
  .galleryslider{
    .item{
      min-height: 12rem;
      min-width: 20rem;
      padding: 20px;
      @media screen and (max-width: 600px) {
        min-height: 6rem;
        min-width: 10rem;
        padding: 15px;
      }
    }
    .itemimg{
height:85%;

width:100%;
border-radius: 20px;
pointer-events: none;
    }
   
    .innercarousel{
      display: flex;
    // background-color: #0a136a;
    }
    .carousel{
      cursor:grab;
      overflow:hidden;
    // background-color: #cb1e1e;
     width: 100% !important;
     margin-left: 0px !important;
     //height: 100%;
    }
  }
  .header_logo_appbar {
    width: 15% !important;
    height: 50% !important;
    padding: 20px !important;
    

    @media screen and (max-width: 600px) {
      width: 100px !important;
    }
  }

 
  
  /*-------------------------------------------------------our team section-------------------------------------------------------------------------*/

.team-container {
  padding: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 600px) {
    padding: 15px !important;
  }
  
  .member-card {
    max-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    // border: 1px solid #003366;
    box-shadow: none!important;
    margin: 0 auto;
    background: none!important;
  
    .member-image-container {
      align-items: center;
      display: flex;
    }
   
    .member-image { 
     object-fit: fit;
    }
  
    .member-name {
      font-size: 1rem;
      margin: 8px 0;
    }
  
    .member-designation {
      font-size: 1rem;
      color: #007bff;
    }
  
    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  
    @media (max-width: 600px) {
      .member-card {
        max-width: 100%;
      }
    }
  }
  }
 /*-----------------------------------------------------------news section-----------------------------------------------------*/
 
 .news-card {
  display: flex;
 // box-shadow: none!important;
  margin: 0 auto;
 // background: none!important;
 }
 .card-container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  text-align: left;

 }
 .cardcontent{
  font-size: 0.8rem !important;
  cursor: pointer;
  color: rgb(18, 17, 17) ;
  padding:1px !important;
  white-space: 'pre-wrap' !important;
   word-break: 'break-word' !important;
  @media (max-width: 600px) {
    font-size: 0.8rem !important;
    padding: 15px!important;
  }
 }

 .cardcontent:hover {
  color: #0c2592;
}
 .news-content{
  position:relative
 }

 .news-content img{
  border-radius: 45px 45px 0px 0px;
 }
 .news-content:before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
 // background: linear-gradient(336deg, #000000f5, transparent);
  content: '';
  border-radius: 45px 45px 0px 0px;
}


.font_style_header-news {
  font-family: "Oxygen", sans-serif !important ;
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  margin-bottom: 20px !important;
  color: #0a136a;
  @media screen and (max-width: 600px) {
    font-size: 1.3rem !important;
  }
}

.custom-card-media {
  width: 351px !important;
  height: 200px !important;
 // margin: 0 auto;
  object-fit: inherit !important;
  align-self: flex-end;
}

.newscard{
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  //background: none !important;
  @media (max-width: 600px) {
    display: inline-block !important;
    width: 100% !important;
    
  }


  .imgstyle {
  max-width:130px !important;
  height: 130px !important; 
   object-fit: cover !important;
   padding-right: 10px;
  // align-self: flex-end;
   overflow: hidden;
   @media (max-width: 600px) {
     max-width: 100% !important;
     object-fit: cover !important;
   }
  
 }
}

.newsimg-container{
  width:100%;
  cursor: pointer;
}

.mainimagestyle{
  width:100%;
height:550px;
object-fit: cover;
overflow-y: scroll;
@media (max-width: 600px) {
  height:350px;
 }
}

.itembarstyle{
  height: 90px;
 // background:none !important;
  font-size: 2.4rem !important;
  text-align: left;
  
}
.textcontent{
  padding:1px !important
  
}

.date_style{
  font-size: 1.3rem !important;
  padding-top: 20px;
  color: #686971;
  padding-bottom: 20px;
  @media (max-width: 600px) {
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 1rem !important;
   }

}
.margin-align{
  padding:100px 100px 200px 100px;
  //margin: 0 auto;
  text-align: left;
  @media (max-width: 600px) {
   padding: 30px 30px 50px 30px;
  }

}
.margin-align-popularnews{
  margin-left: 50px;
  @media (max-width: 600px) {
    margin-left: 0px !important;
    margin-top: 50px;
   }
}

.margin-align-tagnews{
  padding-top: 50px;
  @media (max-width: 600px) {
    padding-top:20px !important;
   }
}



.newsimage {
  width: '100%';
  max-height: '100%';
  object-fit: 'cover' 
}




.textstyle {
  text-align: left !important; 
  font-size: 1rem !important;
  padding-top: 15px;
  padding-bottom: 20px;
  @media (max-width: 600px) {
    font-size: 0.8rem !important;
  padding-bottom: 10px;

  }
}
.newsitem_header{
  font-size: 1.9rem !important;
  color: #131f84;
  font-weight: 700 !important;
  padding-bottom: 20px;
  text-align: justify !important;
  align-items: center !important;
  font-family: "Oxygen", sans-serif !important ;
  @media (max-width: 600px) {
    font-size: 1.2rem !important;
    padding-bottom: 12px;
  }
}

.newsitem_subtitle{
  font-size: 1.2rem !important;
  color: black;
  padding-bottom: 20px;
  text-align: justify !important;
  align-items: center !important;
  font-family: "Oxygen", sans-serif !important ;
  @media (max-width: 600px) {
    font-size: 0.8rem !important;
    padding-bottom: 12px;
  }
}
.para_textstyle {
  text-align: justify !important;
  align-items: center !important;
  font-family: "Oxygen", sans-serif !important ;
  font-size:18px !important;
  @media (max-width: 600px) {
    font-size: 1rem !important;
  }
}

.tag-news-maincontainer{
  overflow-x:auto  !important;
   white-space: nowrap !important; 
}
.tag-news-container{
  display: inline-block !important;
   margin: '0 10px';
 
}

.tag-imagelistitem{
  height: 300px !important;
  width: 300px !important;
  margin-right: 10px
}


.youtubeframe-container{
padding-top: 20px;
padding-bottom: 20px;
}


.no-news-container {
  padding:50px 50px 50px 50px;
  align-content: center;
  //margin: 0 auto;
  @media (max-width: 600px) {
   padding: 30px 30px 50px 30px;
   align-content: center
  } 
img{
  max-width: 200px;
    max-height: 200px;
   // padding-top: 30px;
}


.nonews_text{
  font-weight: 700 !important;
  font-size: 1.4rem !important;
}

.back-home-button {
  width: 200px;
  height: 55px;
  margin-bottom: 10px;
  color: white !important;
  background-color: #131f84!important;
  border-radius: 10px !important;
  font-size: 13px !important;
  font-weight: 900 !important;
  padding: 10px !important;

}


}


.ourteam,
.home,
.gallery,
.news,
.newsitem{
  scroll-margin: 130px; 
}
 .closing_date_info{
    font-weight: bold;
    font-size:large;
  }