.card {
    max-width: 650px;
    width: 80%;
    height: 100% !important;
    padding: 15px;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    box-sizing: border-box;
    align-items: center;
    margin: auto;
}

.icon {
    height: 100px;
    width: 100px;
    margin-top: 5px;

}

h5 {
    font-weight: 200;
    font-size: 17px;

}

h3 {
    font-weight: bold;
    font-size: 17px;
}

h4 {
    font-weight: bold;
    font-size: 17px;
}
h6 {
    font-weight: 700;
    font-size: 12px;
}

.card2 {
    width: 100%;
    max-width: 630px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #ddd;
    background-color: #e4e1e1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    box-sizing: border-box;

}
.paymentcard {
    align-self: center;
    width: 100%;
    max-width: 630px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #ddd;
    /* background-color: #f4f2f2; */
    box-shadow: 0 4px 8px rgba(245, 243, 243, 0.1);
    border-radius: 10px;
    box-sizing: border-box;
    h3 {
        font-weight: bold;
        font-size: 15px;
    }
}
.email-bold {
    font-weight: bold;
}

.btn {
    background-color: rgb(247, 75, 52);
    color: white;
    font-size: 20px;
    font-weight: 900;
    height: 60px;
    width: 250px;
    border-radius: 10px;
    border: 1px solid #ddd;
    margin-top: 1px;
}

.container {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100% !important;
    margin-top: 60px;
    margin-bottom: 40px;
    @media screen and (max-width: 600px) {
        margin-top: 20px !important;
      }
}

.grid-item {
    margin: 5px;
    padding: 0;
    text-align: start;

}

.grid-items-right {
    margin: 5px;
    padding: 0;
    text-align: end;
}

.label,
.value {
    margin: 5px;
    padding: 0;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.934);

}
.infocontainer{
    margin-left: 30px;
    margin-right:30px;
    text-align: justify; 
    display: flex;
    justify-content: space-between;
    
    @media screen and (max-width: 600px) {
        margin-left: 30px !important;
    margin-right: 30px !important;
      }
}