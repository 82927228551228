//sidebar styles
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

$boxShadowColor: rgba(0, 0, 0, 0.1);
.sidebar_paper{
  position: relative;
    width: auto;
    height: 150vh;
    
    padding: 16px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: navy !important;
    @media only screen and (max-width: 600px) {
      width: 230px ;
      height: 120%;
      
      padding: 16px;
      margin-left: 2px;
      margin-top: 0px;
      background-color: 002979
    }
}

.sidebar_paperdesk{
  position: relative;
    width: 230px;
    height: 100vh;
   // overflow-y: hidden !important;
   //overflow-x: hidden;
    padding: 16px;
    margin-left: 2px;
    margin-top: 2px;
    background-color: navy !important;
    @media only screen and (max-width: 600px) {
      width: 230px ;
      height: 120%;
      
      padding: 16px;
      margin-left: 2px;
      margin-top: 0px;
      background-color: 002979
    }
}
.sidebar_paper1{
    width: auto;
    height: 40px;
    margin-top: 15px;
    
    @media only screen and (max-width: 600px) {
      width: auto;
    height: auto;
    margin-top: 15px;
  
}
}
.mobile-menu {
  display: none; // Initially hide the mobile menu
}

@media only screen and (max-width: 600px) {
  .sidebar {
    display: none; // Hide sidebar on small screens
  }

  

  .mobile-menu {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
}
.itemcenter{
    width: auto;
    align-items: center !important;
    padding-left: 10px !important;
    padding-bottom: 20px;
}
.dashboard_container{
  
  
    background-color: #e8eff6;
    min-height: 100vh;
    

.dashmainContent {
  background-color: #F1F4F8 !important;
    height: auto;
    padding-right: 10px;
    padding-top: 5px;
    padding-left: 10px; // Adjust as needed
  }}
  .dashmainContent1 {
    padding-right: 16px;
    padding-top: 5px;
    padding-left: 5px; // Adjust as needed
  }
  
  
  .dash_paper {
    padding: 20px; // Adjust as needed
    background-color: #F1F4F8 !important;
    box-shadow: 0px 2px 4px $boxShadowColor;
    border-radius: 20px !important; 
    width: auto;
    @media (max-width: 600px) {
    padding: 20px; // Adjust as needed
    background-color: white;
    box-shadow: 0px 2px 4px $boxShadowColor;
    border-radius: 20px !important; 
  
    }
   
  }
  .dash_paper1 {
    padding: 20px; // Adjust as needed
    background-color: white;
    border-radius: 20px !important; // Adjust as needed
    border: 1px solid #d9dfdf !important ;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .textcontainer{
    text-align: left !important;
    margin-top: 30px;
    margin-left: 16px;
   // margin-right: 16px !important;
  }
  .textcontainer1{
    text-align: left !important;
    margin-top: 10px;
   // margin-bottom: 20px;
    margin-left: 16px;
  }
  .selectcontainer{
    align-items: end !important;
    justify-content: end !important;
    text-align: left !important;
    margin-top: 10px !important;
    margin-bottom: 5px !important;
   
  }
  
  .dash_title {
    
    font-size: 14px !important;
    font-family: Poppins !important;
   // margin-left: 12px !important;
  }
  .dash_title1 {
    font-weight:600 !important;
    font-size:24px !important;
    font-family: sans-serif!important;
    color: #444848 !important;
   // margin-left: 12px !important;
  }
  @media (max-width: 600px) {
    .dash_title1 {
        font-size: 12px !important;
        /* Additional styles for small screens */
    }
  }
  .dash_title2 {
    font-weight:600 !important;
    font-size:20px !important;
    font-family: sans-serif!important;
    color: #525656 !important;
   margin-left: 12px !important;
  }
  
  .dash_description {
    font-weight:500 !important;
    color: #5d666d;
    font-size: 13px !important;
    margin-top: 8px; // Adjust margin as needed
    font-family:Verdana !important;
    //margin-left: 12px !important;
  }
  .dashbrd_description {
    font-weight:500 !important;
    color: #141617;
    font-size: 33px !important;
    margin-top: 8px; // Adjust margin as needed
    font-family:Poppins !important;
    //margin-left: 12px !important;
  }
  .conferenceSummaryContainer {
    margin-top: 10px !important;
  }
  .conferenceSummaryContainer1 {
    margin-top: 10px !important;
    margin-left: 16px;
  }
  @media only screen and (max-width: 600px) {
    .conferenceSummaryContainer1 {
      margin-top: 10px !important;
      
    }
  }
  .conferenceTitle {
    font-weight: 600;
    font-size: 20px;
  }
  
  .conferenceDescription {
    font-weight: normal;
    color: #57636C;
    font-size: 14px;
  }
  
  .gridContainer {
    border: 1px solid #E0E3E7;
    border-radius: 10px;
    
    background-color: #FFFFFF;
    //height: 400px; for mobile view
  
    .gridItem {
      padding: 16px;
      margin-bottom: 20px !important;
  
      .paperContainer {
        background-color: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        overflow: hidden;
  
        .textContainer {
          padding: 16px;
  
          .title {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 8px; // Add margin-bottom to create space between title and description
          }
  
          .description {
            font-weight: normal;
            color: #57636C;
            font-size: 14px;
          }
        }
  
        
  
          .circularItem {
            text-align: center;
          }
        
      }
    }
  }
  .circularContainer {
    margin-top: 25px;
    margin-bottom: 10px;
    flex-direction: row;
    display: flex;
    justify-content: center;
    
  }
  .progressContainer {
    position: 'relative';
    width: '100%';
    height: '100%';
  
   
    .progressNumber {
      position: absolute !important;
    top: 50% !important; /* Adjust the position from the top as needed */
    left: 50% !important; /* Adjust the position from the left as needed */
    transform: translate(-100%, -100%) !important;
    text-align: center !important;
    }
  }
  .activeUsersContainer {
    width: 100%;
    border-radius: 10px !important;
    padding: 16px;
    background-color:white !important; // Use your primary color
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  }
  @media only screen and (max-width: 600px) {
    .activeUsersContainer {
      width: 100%;
      border-radius: 10px !important;
      padding: 16px;
      background-color:white !important; // Use your primary color
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-top: 10px;
    }
  }
  .cardTitle {
    font-family:  sans-serif; // Adjust font family
    color: rgb(24, 22, 22); // Use your primary button text color
    font-weight: 600 !important;
    font-size: 24px !important;
  }
  
  .cardDescription {
    font-family: 'Your-Body-Medium-Font', sans-serif; // Adjust font family
    color: rgb(48, 46, 46);
    margin-top: 4px; // Adjust margin as needed
  }
  
  .progressContainer1 {
    width: 100%;
    padding-top: 12px; // Adjust padding as needed
    .MuiLinearProgress-bar {
      transition: width 1.5s; // Adjust the animation duration
    }
  }
  .totalUsersContainer {
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #c1c4c4 !important ;
    padding: 10px;
    margin-top: 20px;
    width: 100%; // Add margin top as needed
  }
  .userCountContainer{
    width: 40%;
    background-color: #FFFFFF;
    border-radius: 24px;
    display: flex;
    align-items: center;

  }
  .avataricon{
    color: #090a0a;
    font-size: 44px;
    margin-right: 16px;
  }
  .menuicongrid{
    text-align: left;
    
  }
  .customCircularProgress {
    color: #E0E3E7 !important; // Grey color for the entire circle
    }
    .customCircularProgress1 {
      
        color: #F19642 !important; // Orange color for the progress bar
        position: absolute;
        z-index: 1;
      
    }
    .customCircularProgress2 {
      
      color:#FF5D3A !important; // Use the provided color
      position: absolute;
      z-index: 1;
    
  }
  .mr_top{
    margin-top: 120px;
  }
  .mr_lft{
    margin-left: 40px !important;
  }
  @media only screen and (max-width: 600px) {
    .mr_lft{
      margin-left: 0% !important;
    }
  }
  .customLinearProgress {
  
      width: 100%; // Full width
      border-radius: 50px !important; // Rounded corners
      height: 16px !important; // Adjust height as needed
      background-color:rgb(167, 202, 237) !important;
  
      & .MuiLinearProgress-bar {
        border-radius: 50px !important; // Rounded corners for the progress bar
        background-color:navy !important; // Use the provided color
      }
    
  }
  .up_grid{
    padding-bottom: 12px;
    text-align: left;
    margin-top: 12px;
  }
  .marg_left{
    margin-left: 25px;
  }
  .groupiconstyle{
    font-size: 40px !important;
    color: #28467A;
  }
  .menuiconstyle{
    font-size: 35px !important;
    color: #141617;
  }
  .menu-icon {
    display: none !important;
  
    @media (max-width: 600px) {
      display: inline-block !important;
      margin-left: -10px !important;
      text-align: start !important;
      
    }
  }
  .mgleft{
    margin-left: 10px !important;
  }
  .mglft20{
    margin-left: 20px !important;
    @media (max-width: 600px) {
      margin-left: 0% !important;
    }
  }
  .button_style{
    background-color:rgb(74, 127, 241);
    color:#ffffff;
    border-radius: 5px;
    padding:4px 10px;
    border: none;
    cursor: pointer;
  }
  .button_stylesave{
    background-color:rgb(44, 161, 204);
    color:#ffffff;
    border-radius: 5px;
    padding:4px 4px;
    border: none;
    cursor: pointer;
  }
  .button_style2{
    background-color:#F9BA33;
    color:#ffffff;
    border-radius: 5px;
    padding:4px 10px;
    border: none;
    cursor: pointer;
  }
  .button_style1{
    background-color: rgb(208, 16, 16);
    color:#ffffff;
    border-radius: 5px;
    padding:4px 10px;
    border: none;
    cursor: pointer;
  }
  .button_style_view{
    background-color: rgb(34, 83, 163);
    color:#ffffff;
    border-radius: 5px;
    padding:4px 10px;
    border: none;
    cursor: pointer;
  }
  .linkbutton{
    text-decoration: none;
    color: inherit;
    margin-top: 15px !important;
  }
  // .active-link {
    
  // }
  .active-sidebar {
    width: auto;
    background: linear-gradient(to right,#1C4185,navy);
   padding-top: 10px !important;
   
    
  }
  
  .active-icon {
    color:white !important;
    font-size: 22px !important;
    font-weight: 600 !important; /* or any other desired active color for the icon */
  }
  
  .active-text {
    color:white !important;
    font-size: 16px !important;
    font-weight: 600 !important; /* or any other desired active color for the text */
  }
  
  .viewiconstyle{
    font-size: 16px !important;
  }
  /* Add this to your CSS or styles file */
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: linear-gradient(to bottom right , rgb(245, 218, 204), rgb(212, 207, 205));
  //margin-left: 14px !important;/* Set the background color as needed */
 // margin-right: 14px !important;
}

.headerlogo {
  width: 168px; /* Adjust the width of your logo */
  height:63px;
  margin-left: 20px;
}
.headerlogo1 {
  width: 60px; /* Adjust the width of your logo */
  height:63px;
  
}

.logoutText {
  cursor: pointer;
  margin-left: 10px !important; /* Add pointer cursor to indicate it's clickable */
}
.logoContainer {
  display: flex;
  align-items: center;
  margin-right: 20px;
}



.accountIcon {
  font-size: 30px;
  color: #57636C; /* Adjust the font size as needed */
}
.sidebariconsstyle{
  font-size: 20px !important;
  color: #d4d0d0 !important;
}
.sidebariconsstyle1{
  font-size: 24px !important;
  color: #d4d0d0 !important;
}
  .sidebartitlestyle{
    font-size: 14px !important ;
    font-family: Arial;
    color: #dfdbdb !important;
    font-weight: 500 !important;
  }
  .payment-status-input {
    width: 90px !important; // Adjust the width as per your requirement
   // height: 24px; // Adjust the height as per your requirement
   margin-right: 8px;
    // Add any additional styling as needed
    border: 1px solid #ccc;
    padding: 4px;
    box-sizing: border-box;
  }
  .payment-status-select {
    width: 100%;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .searchformpad{
    margin-top: 40px;
    //margin-bottom: 10px;
    text-align: left;
    margin-left: 16px;
  }
  .redTextGrid {
    background-color:#EF1616;
    color: white;
    font-size: 10px !important;
    border-radius: 10px;
    padding: 3px;
    width:60px !important;
    margin-left: 10px !important;
    font-weight: 600 !important;
    text-align: center;
    
  }
  .orangeTextGrid {
    background-color:#f46e34;
    color: white;
    font-size: 10px !important;
    border-radius: 10px;
    padding: 3px;
    width:70px !important;
    margin-left: 10px !important;
    font-weight: 600 !important;
    text-align: center;
    
  }
  .greenTextGrid {
    background-color: #10B234;
    color: white;
    font-size: 10px !important;
    border-radius: 10px;
    padding: 3px;
    width:50px !important;
    margin-left: 10px !important;
    font-weight: 600 !important;
    text-align: center;
  }
  .arrow-icon {
    position: absolute;
    top: 10%;
    right: -14px;
    transform: translateY(-50%);
    font-size: 42px !important;
    color: #e8eff6;
    box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.2); /* Adjust shadow offsets */
    border-radius: 250% !important; 
    
  
  }
  .arrow-icon1 {
    position: absolute;
    top: 15%;
    right: -19px; /* Adjust the value accordingly */
    transform: translateY(-50%);
    font-size: 42px !important;
    color: #e8eff6;
    box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 50% !important;
    z-index: 1 !important;
  }
  
  /*----------------attendees list-------------------------------*/
  .dash_paper2 {
    padding: 20px; // Adjust as needed
    background-color: #e8eff6;
    border-radius: 20px !important; // Adjust as needed
   
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    max-width: 100% !important;
  }
  .nodata_message{
    text-align: left !important;
    color: red;
    font-size: larger;
    margin-left: 20px;
    padding: 20px;
  }
  .loadingtext{
    font-size: 18px;
    text-align: center;
    margin-top: 40px;
  }
  .dashboardcontainer {
    background-color: #e8eff6;
    min-height: 100vh;
    .sidebar {
        height: 100%;
      }
    .content-container {
      padding: 20px;
      text-align: center;
    }
    @media only screen and (max-width: 600px) {
      .content-container {
        padding: 0%;
       // margin-left: 30px;
        text-align: center; /* Adjust the padding value as needed */
      }
  }
}
/*---------------------primaryappbar-----------------------------*/

.sidebar_home{
  position: relative;
    width: 230px;
    height: 100vh;
    margin-left: 2px;
    background-color: #0a136a !important;
    @media only screen and (max-width: 600px) {
      width: 230px ;
      padding: 16px;
      margin-left: 2px;
      margin-top: 0px;
      background-color: #0a136a !important
       }
}
.sidebar_home_content{
    width: auto;
    height: 40px;
    
    @media only screen and (max-width: 600px) {
      width: auto;
    height: auto;
  
}
}
.divider_style{
  padding: 10px
}
/*--------------print section -------------------*/
.a4-container {
  width: 8.27in; // 210mm
  //height: 11.69in; // 297mm
  margin: auto; // Center the container on the page
  padding: 20px; // Optional padding
  border: 1px solid #ccc; 
  // Optional border
  @media (max-width: 768px) {
    width: 100%; // Make it full width on small screens
    height: auto; // Allow the height to adjust based on content
  }
}
.labelstyle {
   font-weight:bold!important;
    display: inline-block!important ;
    text-align:left!important;
    padding:1px;
    @media (max-width: 768px) {
      //width: 300px; // Make it full width on small screens
      //height: auto; // Allow the height to adjust based on content
    }
  }
.mainheadingstyle{
  font-size: 1.9rem;
      text-align: center;
      font-weight: bold !important;
}
.subheadingstyle{
  font-size: 1.9rem;
      text-align: center;
}
  .datatextsyle{
    width: 200px,;
    display: inline-block
  }

  .headingstyle {
   text-decoration: underline;
    color: white;
      font-size: 1.2rem;
      text-align: left;
      background-color: #28467A !important;
      padding:5px;
      width:100%;
     //width: 100%;
      height: 30px;
      border-radius: 5px;
  }
  .print_btn {
    background-color: rgb(224, 134, 16) !important;
    color: white !important;
  
  }
// printStyles.scss
@media print {
  .a4-container {
    position: relative;
  }

  .a4-container .extra-header,
  .a4-container .extra-footer {
    display: block;
  }

  .non-print-section {
    display: none !important;
  }
  .print-section {
    display: block !important;
  }
  a {
    display: none;
  }
  .headingstyle {
    text-decoration: underline;
     color: rgb(12, 12, 12);
       font-size: 1.2rem;
       text-align: left;
       padding:5px;
       width:100%;
       height: 30px;
       border-radius: 5px;
   }
}

.confnamefield{
  text-align: left;
}
.Tshirt-size-Filter{
  display: none;
}