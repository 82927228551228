// Define variables
$primary-color: #3498db;
$background-color: #ecf0f1;

// Styles for the form container
.form-container {
  max-width: 600px;
  margin: 0 auto;
}

// Styles for the form paper
.form-paper {
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: $background-color !important;
  margin-top: 16px;
  margin-bottom: 16px;
}
.Workshopform-paper {
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: $background-color !important;
  padding-right: 30px !important;
  margin-top: 22px;
  margin-bottom: 16px;
  margin-right: 16px;
  margin-left: 16px;
}
.Workshop_margin{
  margin-right: 16px;
  margin-left: 16px;
}
.Workshop_paddig{
  margin-top: 50px !important;
  margin-bottom: 20px !important;
}

.headergridcell_blue1 {
  border-right:  0.1px solid #E1E1E1 !important;
 // border-left: 1px solid #ffff;
  font-size: 14px;
  text-align: center;
  background-color:navy;
  color: white;
}
.gridcolumn-cells {
  border-right: 0.1px solid #E1E1E1 !important;
  padding: 1px !important;
}
  .bg_grey_TableCell {
    background-color: #F9F9F9;
    &:hover {
      background-color: #F9F9F9 !important;
      color: inherit;
    }
  }
  .bg_white_TableCell {
    background-color: #FFFF;
    &:hover {
      background-color: #FFFF !important;
      color: inherit;
    }
  }

// Styles for the form title
.form-title {
  color: rgb(29, 28, 28) !important;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 500 !important;
}

// Styles for the form fields
.form-field {
  margin-bottom: 15px;

  & input {
    background-color: white;
    // Add other input styles here
  }
}

// Styles for the form button
.form-button {
  background-color: $primary-color !important;
  color: #fff;
  &:hover {
    background-color: darken($primary-color, 10%);
  }
}
.conferenceadd-textfield {
  background-color: white !important;
  // Add other styles as needed
}
.conferenceadd-textarea{
  width: 100%;
  border: 1px solid #ccc

}
.quilltext{
  height: 240px;
}
.confaddtable-heading{
  text-align: left;
  font-size: 1.5rem; // Adjust font size as needed
  font-weight:600 !important;
  margin-bottom: 10px;
}
.confadd_description{
 text-align: left;
 color: #5e6060;
 font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 0.875rem;
}
.custom-table-container {
  border: 1px solid #ccc; // Add the border style you desire
  border-radius: 8px; // Add border-radius for rounded corners if needed
  overflow: hidden; // Hide overflow for cleaner appearance
}

.custom-table {
  width: 100%;
  border-collapse: collapse; // Collapse table borders
}

.custom-table th,
.custom-table td {
  border: 1px solid #ccc; // Add border style for table cells
  padding: 8px; // Add padding for cell content
}

.gridiconstyle{
  text-align: left !important;
  margin-left: 15px !important;
}


.button_add_volunteer {
  background-color: #3498db !important;
  color: #fff !important;
  border: none !important;
  padding: 8px 16px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  align-items: left !important;
}

.button_add_volunteer:hover {
  background-color: #2980b9 !important;
}


.button_add_volunteer .MuiSvgIcon-root {
  margin-right: 10px !important; 
}

.adminamountdetails{
  margin-top: 70px;
}

.gallery_typography{
  color: #0F1A51;
  padding: 20px;
  padding-top: 50px;
}

.imageupload_icon{
  width: 30px;
  height: 30px;
  font-size: 45px !important;
}

.addimagebtn{
  margin-top: 20px !important;
 
}

.Delete_btn{
  background-color: #C21919 !important;
  color: white !important;
}

.typography_file{
  color: #292D32;
}

.Browse_btn {
  padding: 8px 16px;
  background-color: white;
  color: #54575c;
  border: 2px solid #cbd0dc; /* Add an initial border */
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  /* Add any additional styles here */
}

.formats_image{
  color: #A9ACB4;
  font-size: small;
}

.view_image{
  height: 240px;
  width:230px
}

.admin_reg{
  margin-bottom: 60px;
}

.Admin_reg_margine{
  padding-left: 45px !important;
}
.search-form-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.search-grid-container {
  justify-content: space-between;
  align-content: center;
  padding-left: 30px;
  padding-top: 30px;
}
.formsubmitbtn{
  height: 40px !important;
  margin-top: 8px !important;

}