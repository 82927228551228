


.main-grid-privacy{
    position: relative;
    align-items: stretch;
    /* background: #000; */
    height: 370px;
    overflow: hidden;
}
.image-privacy{
    width:100%;
}

.privacy-h6{
    font-family: 'Oxygen', sans-serif !important;
    font-weight: 700 !important;
    margin: 0 !important;
    color: #162447 !important;
    text-align: left !important;
}
.container-privacy{
    text-align: left !important;
    margin-left:100px;
    @media (max-width:600px) {
        margin-left:10px;  
    }
}
.privacy-h5{
    font-family: 'Oxygen', sans-serif !important;
    font-weight: 900 !important;
    margin: 0 !important;
    color: #162447 !important;
}
.paragraph-privacy{
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    margin: 0 !important;
    font-family: open sans, sans-serif !important;
    padding: 13px 0 !important;
    color: #6c757d !important;
}
.table-columns1{
    font-size: 14px !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    margin: 0 !important;
    font-family: open sans, sans-serif !important;
    margin: 2px !important;
    color: #6c757d !important;
}
.table-columns2{
   
    font-size: 14px !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    margin: 0 !important;
    font-family: open sans, sans-serif !important;
   
    color: #6c757d !important;
    background-color: #e2e3e5 !important;
    margin: 2px !important;
}
.link-privacy{
    color:blue;
}
.privacy-td{
    border: 1px solid #666666 !important;
    text-align: left !important;
    padding: 0px !important;
    width: 50% !important;
  
}
.heading-privacy {
    font-family: 'Oxygen', sans-serif;
    // margin-top: 100px;
    font-weight: 700;
    color: black;
    font-style: normal;
    font-size: 40px;
    margin-left: 93px;
    text-align: left;
    // padding-top: 120px;
}

.cs-grid{
    width: 100%;
}

.main-grid-privacy h1{
    position: absolute;
    top: 140px;
}

@media  (max-width:1040px){
    .main-grid-privacy{
       
        height: auto;
        overflow: hidden;
    }
}


@media  (max-width:735px){
.main-grid-privacy h1{

    transform: translate(0%, 210%);
    font-size: 30px;
    top: 0;
}
}

@media  (max-width:500px){
    .main-grid-privacy h1{

        transform: translate(0%, 174%);
        font-size: 26px;
        top: 0;
        margin-left: 15px;
    }  
}

@media  (max-width:350px){
    .main-grid-privacy h1{

        transform: translate(0%, 150%);
        font-size: 22px;
        top: 0;
        margin-left: 15px;
    }  
}

@media  (max-width:360px) and (min-width:350px){
    .main-grid-privacy h1{

        transform: translate(0%, 150%);
        font-size: 20px;
        top: 0;
        margin-left: 15px;
    }  

}

@media  (max-width:600px){
    .main-grid-privacy h1{

        margin-left: 10px;
    }  
}
.paragraph-privacy-gray{
    font-size: 18px !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    margin: 0 !important;
    font-family: open sans, sans-serif !important;
    padding: 13px 0 !important;
    color: #6c757d !important;
    
}
.table-terms {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    margin: 0 !important;
    font-family: 'Open Sans', sans-serif !important;
    color: #6c757d !important;
    margin: 2px !important;
    border:#6c757d  ;


    
.table-terms-td {
    border: none !important; /* Remove left border for all table cells */
    padding: 5px 10px;
  border-bottom: none !important; /* Add padding for better spacing, adjust as needed */
}
.link-privacy{
    color:blue;
}
/* Optional: Add a border to the bottom of each row for separation */

}